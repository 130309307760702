import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi, I'm Eugene.`}</p>
    <p>{`I'm an engineering leader at `}<a parentName="p" {...{
        "href": "https://web.seesaw.me"
      }}>{`Seesaw`}</a>{`, a learning platform for PreK - 5.`}</p>
    <p>{`In the past, I've worked at `}<a parentName="p" {...{
        "href": "https://www.duettocloud.com"
      }}>{`Duetto`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.humandx.org"
      }}>{`The Human Diagnosis Project`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.salesforce.com"
      }}>{`Salesforce`}</a>{`. I've also researched how to safely and autonomously fly quadrotors with `}<a parentName="p" {...{
        "href": "http://hybrid.eecs.berkeley.edu/"
      }}>{`Professor Claire Tomlin`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      